import { Component, OnInit } from '@angular/core';

import  * as _localidades  from '../../../assets/provincias.json';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  localidades: any;
  provincias: any;

  constructor() { 

    let localidades: any = _localidades;
    this.localidades = localidades.default.localidades;
    this.provincias = localidades.default.provincias;
    
  }

  ngOnInit(): void {
  }

}
