<footer>
	<h2>SUCURSALES</h2>
	<mat-divider></mat-divider>
	<section>
		<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2357.195776672996!2d-67.700928!3d-53.786003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc6bd5aaf90f06191!2sCredisur%20R%C3%ADo%20Grande!5e0!3m2!1ses-419!2sar!4v1590015528165!5m2!1ses-419!2sar" width="100%" height="250" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
		<h2>Rio Grande</h2>
		<mat-divider></mat-divider>
		<article>
			<p>Av. San Martín 178.<br>Tierra del Fuego.</p>
			<p>2964-431701</p>
			<a mat-raised-button color="primary" href="https://bit.ly/3ci8jSj">Enviar <img src="../../assets/img/redes/red_1.png"></a>
		</article>
	</section>
	<section>
		<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2299.445323571446!2d-68.31315238452882!3d-54.807290980311066!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbc4c2368c9acd163%3A0x8f60dd3a088fb0b4!2sCredisur%20Ushuaia!5e0!3m2!1ses-419!2sar!4v1590014172408!5m2!1ses-419!2sar" width="100%" height="250" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
		<h2>Ushuaia</h2>
		<mat-divider></mat-divider>
		<article>
			<p>Gral. Juan Manuel Rosas 188.<br>Tierra del Fuego.</p>
			<p>2901-609559</p>
			<a mat-raised-button color="primary" href="https://bit.ly/32KmQBX">Enviar <img src="../../assets/img/redes/red_1.png"></a>
		</article>
	</section>
</footer>