import { Component, OnInit } from '@angular/core';
import { ContactoService } from '../../services/contacto.service';
import { SimuladorService  } from '../../services/simulador.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {

  estado: string = "enviando";

  constructor(
    private ContactoServicio: ContactoService,
    private SimuladorService: SimuladorService,
    private router: Router
  ) {}

  ngOnInit(): void {

    if(this.SimuladorService.simulado && this.SimuladorService.solicitado){
      this.enviar_datos();
    }else{
      this.router.navigate(['/solicitud']);
    }
  }

  enviar_datos(){

    let datos: any = this.SimuladorService.datos_simular;

    this.ContactoServicio.EnvFormSimu(datos).subscribe(
      res => {
        this.estado = "ok";
      },
      err => {
        this.estado = "fal";
      }
    );
  }
}

