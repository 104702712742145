<section class="contenedor">
    <article class="centro">
        <div>
            <img src="../../assets/img/productos/elemento_1.png">
            <h2>A TU MEDIDA</h2>
            <p>Préstamos personales a la medida de tu necesidad.<br> El dinero que necesitas a pagar en la cantidad de cuotas que elijas.<br> De $50.000 a $3.000.000 a pagar en hasta 24 cuotas fijas y en pesos.</p>
            <span>
			<a [routerLink]="['/solicitud']" (click)="translado('#solicitud')">Consultá</a>
		</span>
        </div>
        <div>
            <img src="../../assets/img/productos/elemento_2.png">
            <h2>FÁCIL Y RÁPIDO</h2>
            <p>Mínimos requisitos, lo tramitás online y lo acreditamos en tu cuenta!</p>
            <span>
			<a [routerLink]="['/solicitud']" (click)="translado('#solicitud')">Pedilo</a>
		</span>
        </div>
        <div>
            <img src="../../assets/img/productos/elemento_3.png">
            <h2>SEGURO</h2>
            <p>Somos transparentes y respetuosos.<br> Cuidamos tus datos personales y te damos toda la información antes de contratar.<br> Te damos el contrato completo y sin letra chica!</p>
            <span>
			<a [routerLink]="['/legales/true']" >Más info</a>
		</span>
        </div>
        <!--<div>
		<img src="../../assets/img/productos/elemento_4.png">
		<h2>Titulos</h2>
		<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
		tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
		quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
		consequat.</p>
		<span>
			<a href="">Más info</a>
		</span>
	</div>-->
    </article>
</section>
