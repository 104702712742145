import { Component,} from '@angular/core';
import { ContactoService } from '../services/contacto.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-estudio',
  templateUrl: './estudio.component.html',
  styleUrls: ['./estudio.component.css']
})
export class EstudioComponent{

  btn_text: string = "Enviar";
  btn_color: string = "background-color: cornflowerblue;";

  contacto = new FormGroup({
    nombre: new FormControl('',[Validators.required, Validators.minLength(5)]),
    dni: new FormControl('',[Validators.required]),
    telefono: new FormControl('',[Validators.required, Validators.minLength(6), Validators.maxLength(8)]),
    email: new FormControl(''),
    motivo: new FormControl('',[Validators.required]),
    obs: new FormControl('',[Validators.required]),
    region: new FormControl('tdf'),
  })

  constructor(
    private ContactoServicio: ContactoService
  ) {}


  btn_enviar(event){

    let datos: any = this.contacto.value;

    if(this.contacto.valid){

      this.btn_text = "Enviando...";
      this.btn_color = "background-color: darkkhaki;";
      this.contacto.disable();

      this.ContactoServicio.EnvFormContactoEstudio(datos).subscribe(
        res => {

          this.btn_text = "Enviado";
          this.btn_color = "background-color: green;";

        },
        err => {

          this.btn_text = "Error revise su correo";
          this.btn_color = "background-color: red;";

        }
      );

    }

  }


}
