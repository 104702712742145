import { Component, OnInit } from '@angular/core';

import  * as _localidades  from '../../../assets/provincias.json';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {

  localidades: any;
  provincias: any;

  constructor() { 

    let localidades: any = _localidades;
    this.localidades = localidades.default.localidades;
    this.provincias = localidades.default.provincias;
    
  }

  ngOnInit(): void {
  }

}
