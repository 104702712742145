<section class="contenedor">
    <article id="requisitos">
        <h2>Requisitos</h2>
        <ul>
            <li>DNI</li>
            <li>Servicio a tu nombre o constancia de domicilio</li>
        </ul>
        <ul>
            <li>Últimos 3 recibos de sueldo</li>
            <li>Movimientos bancarios de los últimos 60 días.</li>
        </ul>
    </article>
    <div class="simu-forms">
        <app-form-principal>
        </app-form-principal>
    </div>
</section>
