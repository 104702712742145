import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css']
})
export class ProductosComponent implements OnInit {

	translado(id)
    {
      event.preventDefault();
      let x = document.querySelector(id);
      if (x)
        {
            x.scrollIntoView({behavior: 'smooth'});
        }
    }
    
  constructor() { }

  ngOnInit(): void {
  }

}
