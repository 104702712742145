<nav>
    <section class="logo">
        <a [routerLink]="['/principal']" (click)="translado('#principal')"><img src="{{logo}}"></a>
    </section>
    <span id="menu" (click)="abrir()"><img src="{{menu}}"></span>
    <section class="{{modal}}">
        <span id="cerrar" (click)="cerrar()"><img src="{{close}}"></span>
        <div class="modal-contenido">
            <a [routerLink]="['/eleginos']" (click)="translado('#eleginos')">ELEGINOS</a>
            <a [routerLink]="['/solicitud']" (click)="translado('#solicitud')">SOLICITUD</a>
            <a [routerLink]="['/clientes']" (click)="translado('#clientes')">CLIENTES</a>
            <a [routerLink]="['/contacto']" (click)="translado('#contacto')">CONTACTO</a>
            <a [routerLink]="['/legales/true']" target="_blank">LEGALES</a>
            <a href="http://www.credisurtdf.com.ar/turnos/" target="_blank">TURNOS</a>
            <a [routerLink]="['/ingresar']">INGRESO</a>
        </div>
    </section>
</nav>