<app-navbar></app-navbar>
<section id="login">
    <form>
        <div class="col-doble">
            <mat-form-field>
                <mat-label>NOMBRE DE USUARIO</mat-label>
                <input matInput type="text">
            </mat-form-field>
            <mat-form-field>
                <mat-label>PASSWORD</mat-label>
                <input matInput type="password">
            </mat-form-field>
        </div>
        <mat-form-field>
            <mat-label>PROVINCIA</mat-label>
            <mat-select matNativeControl>
                <mat-option value="none"></mat-option>
                <mat-option *ngFor="let item of provincias" [value]="item.id">{{ item.prov }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>LOCALIDAD</mat-label>
            <mat-select matNativeControl>
                <mat-option value="none"></mat-option>
                <mat-option *ngFor="let item of localidades" [value]="item.id">{{ item.loc }}</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="col-doble">
            <button mat-raised-button color="primary" type="submit">Ingresar</button>
            <h3>Sos nuevo?, podes registrate <a [routerLink]="['/registrarse']">acá</a>.</h3>
        </div>
    </form>
</section>