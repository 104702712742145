import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-legales',
  templateUrl: './legales.component.html',
  styleUrls: ['./legales.component.css']
})
export class LegalesComponent implements OnInit{

  navbar: boolean = false;

  constructor(private rutaActiva: ActivatedRoute) {

   }


  ngOnInit(): void {

    if(this.rutaActiva.snapshot.params.navbar == "true"){
      this.navbar = true;
    }

  }

}