import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit{

  seccion: string = "";

  constructor(private rutaActiva: ActivatedRoute) {


  }

  ngOnInit(): void {

    this.seccion = this.rutaActiva.snapshot.params.seccion;

  }


}