<app-navbar></app-navbar>
<section id="perfil">
    <article>
        <div id="imagen"></div>
        <div id="principal">
            <div class="info">
                <p class="titulo">Nombre y Apellido:</p>
                <h2>Juan David Alderete</h2>
                <span class="material-icons">create</span>
            </div>
            <div class="info">
                <p class="titulo">DNI:</p>
                <h2>10.100.100</h2>
                <span class="material-icons">create</span>
            </div>
            <div class="info">
                <p class="titulo">Direccion actual:</p>
                <h2>Rosales 123</h2>
                <span class="material-icons">create</span>
            </div>
        </div>
        <div class="secundario">
            <div class="titulo-sec">
                <h3 class="titulo">Telefonos:</h3>
            </div>
            <div class="info">
                <p class="titulo">Telefono 1</p>
                <h2>(2964)-525540</h2>
            </div>
            <div class="info">
                <p class="titulo">Telefono 2</p>
                <h2>(2964)-525540</h2>
            </div>
            <div class="info">
                <p class="titulo">Telefono 3</p>
                <h2>(2964)-525540</h2>
            </div>
        </div>
        <div class="secundario">
            <div class="titulo-sec">
                <h3 class="titulo">Cuotas</h3>
            </div>
            <div class="info">
                <p class="titulo">Cuota 1</p>
                <h2>$1500</h2>
            </div>
            <div class="info">
                <p class="titulo">Cuota 2</p>
                <h2>$1500</h2>
            </div>
            <div class="info">
                <p class="titulo">Cuota 3</p>
                <h2>$1500</h2>
            </div>
            <div class="info">
                <p class="titulo">Cuota 4</p>
                <h2>$1500</h2>
            </div>
            <div class="info">
                <p class="titulo">Cuota 5</p>
                <h2>$1500</h2>
            </div>
        </div>
    </article>
</section>