import { Component, Input, OnInit} from '@angular/core';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit{

  @Input() seccion: string;

  menu = '../../assets/img/menu.svg';
  close = '../../assets/img/close.svg';
  logo = '../../assets/img/logoColor_1.png';

  modal = 'modal';

  ngOnInit(): void {
    this.translado('#' + this.seccion);
  }

  abrir() {
    this.modal = 'modal visible';
  }

  cerrar(){
    this.modal = 'modal';
  }

  translado(id){
    const x = document.querySelector(id);
    if (x)
      {
          x.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
          if (this.modal === 'modal visible'){this.modal = 'modal'; }
      }
  }
}
