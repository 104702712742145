import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SimuladorService {

  datos_simular: any;
  simulado: boolean = false;
  solicitado: boolean = false;

  constructor() { }
}
