<app-navbar></app-navbar>
<section id="registrar">
    <form>
        <div class="col-doble">
            <mat-form-field>
                <mat-label>NOMBRE Y APELLIDO</mat-label>
                <input matInput type="text">
            </mat-form-field>
        </div>
        <mat-form-field>
            <mat-label>DNI</mat-label>
            <input matInput type="number">
        </mat-form-field>
        <mat-form-field>
            <mat-label>TELEFONO</mat-label>
            <input matInput type="number">
        </mat-form-field>
        <mat-form-field class="2columnas">
            <mat-label>PROVINCIA</mat-label>
            <mat-select matNativeControl>
                <mat-option value="none"></mat-option>
                <mat-option *ngFor="let item of provincias" [value]="item.id">{{ item.prov }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>LOCALIDAD</mat-label>
            <mat-select matNativeControl>
                <mat-option value="none"></mat-option>
                <mat-option *ngFor="let item of localidades" [value]="item.id">{{ item.loc }}</mat-option>
            </mat-select>
        </mat-form-field>
        <div class="col-doble">
            <mat-form-field>
                <mat-label>DIRECCION</mat-label>
                <input matInput type="text">
            </mat-form-field>
            <mat-form-field>
                <mat-label>EMAIL</mat-label>
                <input matInput type="email">
            </mat-form-field>
            <mat-form-field>
                <mat-label>NOMBRE DE USUARIO</mat-label>
                <input matInput type="text">
            </mat-form-field>
            <mat-form-field>
                <mat-label>PASSWORD</mat-label>
                <input matInput type="password">
            </mat-form-field>
            <button mat-raised-button color="primary" type="submit">Registrar</button>
        </div>
    </form>
</section>