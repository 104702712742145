<form class="form">
    <div class="grande">
        <h1>Gracias!</h1>
        <div style="color: orangered" *ngIf="estado == 'enviando'">
            <h2>Un momento por favor.</h2>
            <h2>Su solicitud se esta enviando...</h2>
        </div>
        <div style="color: green" *ngIf="estado == 'ok'">
            <h2>Hemos recibido su solicitud.</h2>
            <h2>Lo contactarán en breve.</h2>
        </div>
        <div style="color: red" *ngIf="estado == 'fal'">
            <h2>Lo sentimos mucho, su solicitud no se envío.</h2>
            <h2>Intente nuevamente mas tarde.</h2>
        </div>
    </div>
</form>
