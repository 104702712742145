import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[mascaraSoloNumeroInput]'
})
export class MascaraSoloNumeroInputDirective {

  constructor(
    private elem: ElementRef
  ) { }

  @HostListener('keyup') 
  enmascararInput() {
    let valor = this.elem.nativeElement.value.replace(/\D/g, '');

    if (valor.match(/[0-9]/g)) {
      this.elem.nativeElement.value = valor;
    } else {
      this.elem.nativeElement.value = '';
    }
  }

}
