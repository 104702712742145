import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import  * as _localidades  from '../../../assets/provincias.json';

import { SimuladorService } from '../../services/simulador.service'

@Component({
  selector: 'app-simular',
  templateUrl: './simular.component.html',
  styleUrls: ['./simular.component.css'],
})
export class SimularComponent implements OnInit{

  legal_activo: boolean = false;
  localidades: any;
  localidadesxprovincias: any;
  provincias: any;

  Simular = new FormGroup({
    provincia: new FormControl('',[Validators.required]),
    localidad: new FormControl('',[Validators.required]),
    nombre: new FormControl('',[Validators.required, Validators.minLength(5)]),
    dni: new FormControl('',[Validators.required, Validators.minLength(7), Validators.maxLength(8)]),
    area: new FormControl('',[Validators.required, Validators.minLength(2), Validators.maxLength(5)]),
    telefono: new FormControl('',[Validators.required, Validators.minLength(6), Validators.maxLength(8)]),
    sexo: new FormControl('',[Validators.required]),
    importe: new FormControl('', [Validators.required]),
    cuotas: new FormControl('', [Validators.required]),
  })


  constructor(private router: Router, private SimuladorService: SimuladorService ) {

      let localidades: any = _localidades;

      this.localidades = localidades.default.localidades;
      this.provincias = localidades.default.provincias;

      this.onSelectProvincia();

    }

  ngOnInit(){

    if(this.SimuladorService.simulado && this.SimuladorService.datos_simular != "null"){

      this.Simular.get("provincia").setValue(this.SimuladorService.datos_simular.provincia);
      this.Simular.get("localidad").setValue(this.SimuladorService.datos_simular.localidad);
      this.Simular.get("nombre").setValue(this.SimuladorService.datos_simular.nombre);
      this.Simular.get("dni").setValue(this.SimuladorService.datos_simular.dni);
      this.Simular.get("area").setValue(this.SimuladorService.datos_simular.area);
      this.Simular.get("telefono").setValue(this.SimuladorService.datos_simular.telefono);
      this.Simular.get("sexo").setValue(this.SimuladorService.datos_simular.sexo);
      this.Simular.get("importe").setValue(this.SimuladorService.datos_simular.importe);
      this.Simular.get("cuotas").setValue(this.SimuladorService.datos_simular.cuotas);

    }

  }

  onSelectProvincia(){

    this.Simular.get('provincia').valueChanges.subscribe(

      val => {
        console.log(val);
        this.localidadesxprovincias = this.localidades.filter(item => item.porv_id == val);
      }

    );

  }

  check_legales(val){

    this.legal_activo = !this.legal_activo;

  }

  btn_simular(val){

    if(this.Simular.valid){

        this.SimuladorService.datos_simular = this.Simular.value
        this.SimuladorService.simulado = true;

        let datos: any;

        datos = this.localidades.filter(item => item.id == this.Simular.value.localidad);
        this.SimuladorService.datos_simular.loc = datos[0].loc;
        datos = this.provincias.filter(item => item.id == this.Simular.value.provincia);
        this.SimuladorService.datos_simular.prov = datos[0].prov;

        this.router.navigate(['/solicitud/solicitar']);

    }
  }
}

