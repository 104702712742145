import { Directive, ElementRef, HostListener } from '@angular/core';
import { formatNumber } from '@angular/common';

@Directive({
  selector: '[mascaraNumeroInput]'
})
export class MascaraNumeroInputDirective {

  constructor(
    private elem: ElementRef
  ) { }

  @HostListener('keypress', ['$event'])
  prueba(event) {
    return (event.charCode >= 48 && event.charCode <= 57);
  }

  @HostListener('keyup')
  enmascararInput() {
    let valor = this.elem.nativeElement.value.replace(/\D/g, '');

    if (valor.match(/[0-9]/g)) {
      this.elem.nativeElement.value = formatNumber(valor, 'es-AR');
    } else {
      this.elem.nativeElement.value = '';
    }
  }

}
