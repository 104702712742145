import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';


@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
  animations: [
    trigger('slideAnimacion', [transition('void => *', [style({ opacity: 0, transform: 'scale(0.5)'}),
    animate('500ms', style({ opacity: 1, transform: 'scale(1)' }))]),
    transition('* => void', [animate('500ms', style({ opacity: 0, transform: 'scale(0.5)' }))])])
    ]
})
export class SliderComponent implements OnInit {

  slides = [
    // { src: '../../assets/img/slide/01.png', href: '#solicitud', clase: 'dot select'},
    // { src: '../../assets/img/slide/02.png', href: '#solicitud', clase: 'dot'},
    // { src: '../../assets/img/slide/03.png', href: '#solicitud', clase: 'dot'},
    // { src: '../../assets/img/slide/04.png', href: '#solicitud', clase: 'dot'},
    // { src: '../../assets/img/slide/05.png', href: '#solicitud', clase: 'dot'},
    { src: '../../assets/img/slide/06.png', href: '#solicitud', clase: 'dot select'},
  ];
  actual = 0;
  time: any = false;

  anterior(){
    this.slides[this.actual].clase = 'dot';
    const anterior = this.actual - 1;
    this.actual = anterior < 0 ? this.slides.length - 1 : anterior;
    this.slides[this.actual].clase = 'dot select';
  }

  siguiente(){
    this.slides[this.actual].clase = 'dot';
    const siguiente = this.actual + 1;
    this.actual = siguiente === this.slides.length ? 0 : siguiente;
    this.slides[this.actual].clase = 'dot select';
  }

  Seleccionar(index){
    clearInterval(this.time);
    for (const item of this.slides) {
       item.clase = 'dot';
    }
    const siguiente = this.actual + 1;
    this.actual = index;
    this.slides[index].clase = 'dot select';
  }

  translado(id){
    // tslint:disable-next-line: deprecation
    event.preventDefault();
    const x = document.querySelector(id);
    if (x){
       x.scrollIntoView({behavior: 'smooth'});
    }
  }

  ngOnInit(): void {
    this.time = setInterval(() => {
      this.siguiente();
    }, 5000);
  }

}
