<form class="form" [formGroup]="Solicitud">
    <mat-form-field>
        <mat-label>NOMBRE Y APELLIDO</mat-label>
        <input readonly="true" formControlName="nombre" type="text" matInput>
    </mat-form-field>
    <mat-form-field>
        <mat-label>DNI</mat-label>
        <input readonly="true" formControlName="dni" type="number" matInput>
    </mat-form-field>
    <mat-form-field>
        <mat-label>VALOR DE LA CUOTA</mat-label>
        <input readonly="true" formControlName="imp_cuota" type="text" matInput>
    </mat-form-field>
    <mat-form-field>
        <mat-label>MAIL</mat-label>
        <input formControlName="email" type="email" matInput>
    </mat-form-field>
    <mat-form-field>
        <mat-label>RELACION LABORAL</mat-label>
        <mat-select formControlName="relacion" matNativeControl>
            <mat-option value="AMA DE CASA">AMA DE CASA</mat-option>
            <mat-option value="AUTÓNOMO">AUTÓNOMO</mat-option>
            <mat-option value="EMPLEADO DOMÉSTICO">EMPLEADO DOMÉSTICO</mat-option>
            <mat-option value="EMPLEADO PRIVADO">EMPLEADO PRIVADO</mat-option>
            <mat-option value="EMPLEADO PÚBLICO">EMPLEADO PÚBLICO</mat-option>
            <mat-option value="JUBILADO NACIONAL">JUBILADO NACIONAL</mat-option>
            <mat-option value="MONOTRIBUTISTA">MONOTRIBUTISTA</mat-option>
            <mat-option value="PENSIONADO NACIONAL">PENSIONADO NACIONAL</mat-option>
            <mat-option value="PENSIONADO PROVINCIAL">PENSIONADO PROVINCIAL</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>SUELDO NETO</mat-label>
        <mat-label *ngIf="Solicitud.get('sueldo').hasError('minlength') || Solicitud.get('sueldo').hasError('maxLength')"> Mínimo 5</mat-label>
        <input mascaraSoloNumeroInput formControlName="sueldo" type="text" matInput>
    </mat-form-field>
    <mat-form-field class="grande">
        <mat-label>MOTIVO</mat-label>
        <input formControlName="motivo" type="text" matInput>
    </mat-form-field>
    <div class="parrafo-legal">
        "Los valores de cuota arrojados son según la T.E.A. indicada en los legales para un plan específico, no incluye impuestos, gastos administrativos, es indicativo y como tal no constituye una oferta completa que obligue al titular en esos términos."
    </div>
    <div class="captcha">
        <re-captcha siteKey="6LfzeN0SAAAAAEBZT1kNHKGShSAM_vemLK7285bm"></re-captcha>
    </div>
    <div class="terminos">
        <mat-checkbox (click)="check_legales($event)" color="primary"><a [routerLink]="['/legales/true']" target="_blank">Acepto términos y condiciones</a></mat-checkbox>
    </div>
    <div class="control-btn">
        <button class="btn_volver" (click)="btn_volver()" type="submit">Volver a Simular</button>
        <button mat-raised-button color="primary" type="submit" [disabled]="!legal_activo" (click)="btn_solicitar($event)">Solicitar</button>
    </div>
</form>
