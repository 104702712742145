<section [attr.id]='"estudio"' id="estudio">
    <form [formGroup]="contacto" class="form">
        <mat-form-field>
            <mat-label>NOMBRE Y APELLIDO</mat-label>
            <input formControlName="nombre" type="text" matInput>
        </mat-form-field>
        <mat-form-field>
            <mat-label>DNI</mat-label>
            <input formControlName="dni" type="number" matInput>
        </mat-form-field>
        <mat-form-field>
            <mat-label>TELEFONO</mat-label>
            <input formControlName="telefono" type="number" matInput>
        </mat-form-field>
        <mat-form-field>
            <mat-label>MAIL</mat-label>
            <input formControlName="email" type="email" matInput>
        </mat-form-field>
        <mat-form-field>
            <mat-label>MOTIVO DE LA CONSULTA</mat-label>
            <mat-select formControlName="motivo">
                <mat-option value="PONERSE AL DÍA CON LA EMPRESA">PONERSE AL DÍA CON LA EMPRESA</mat-option>
                <mat-option value="ACUERDO EXTRAJUDICIAL">ACUERDO EXTRAJUDICIAL</mat-option>
                <mat-option value="OBTENER INFORMACIÓN">OBTENER INFORMACIÓN</mat-option>
                <mat-option value="OTROS">OTROS</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>OBSERVACIONES</mat-label>
            <input formControlName="obs" type="text" matInput>
        </mat-form-field>
        <div class="captcha">
            <re-captcha siteKey="6LfzeN0SAAAAAEBZT1kNHKGShSAM_vemLK7285bm"></re-captcha>
        </div>
        <button (click)="btn_enviar($event)" mat-raised-button [style]="btn_color" type="submit">{{btn_text}}</button>
    </form>
</section>