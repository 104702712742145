<section id="archivos">
<form>
	<h2>Nuevas Imagenes para Slider</h2>
	<div>
	<h3>Imagen 01</h3>
	<input type="file" name="imagen" placeholder="seleccione una imagen">
	<mat-form-field appearance="fill">
  		<mat-label>Enlace a seccion:</mat-label>
		<mat-select>
			<mat-option value="none"></mat-option>			
			<mat-option value="eleginos">Eleginos</mat-option>
			<mat-option value="solicitud">Solicitud</mat-option>
			<mat-option value="clientes">Clientes</mat-option>
			<mat-option value="contacto">Contacto</mat-option>
		</mat-select>
	</mat-form-field>
	</div>
	
	<div>
	<h3>Imagen 02</h3>
	<input type="file" name="imagen" placeholder="seleccione una imagen">
	<mat-form-field appearance="fill">
  		<mat-label>Enlace a seccion:</mat-label>
		<mat-select>
			<mat-option value="none"></mat-option>			
			<mat-option value="eleginos">Eleginos</mat-option>
			<mat-option value="solicitud">Solicitud</mat-option>
			<mat-option value="clientes">Clientes</mat-option>
			<mat-option value="contacto">Contacto</mat-option>
		</mat-select>
	</mat-form-field>
	</div>
	<!--
	<div>
	<h3>Imagen 03</h3>
	<input type="file" name="imagen" placeholder="seleccione una imagen">
	<mat-form-field appearance="fill">
  		<mat-label>Enlace a seccion:</mat-label>
		<mat-select>
			<mat-option value="none"></mat-option>			
			<mat-option value="eleginos">Eleginos</mat-option>
			<mat-option value="solicitud">Solicitud</mat-option>
			<mat-option value="clientes">Clientes</mat-option>
			<mat-option value="contacto">Contacto</mat-option>
		</mat-select>
	</mat-form-field>
	</div>
-->
	<div class="botones">
		<button mat-raised-button>Agregar +</button>
		<button mat-raised-button color="primary" type="submit">Finalizar</button>
	</div>
</form>
</section>