import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ContactoService } from '../../services/contacto.service';
import { SimuladorService  } from '../../services/simulador.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-solicitar',
  templateUrl: './solicitar.component.html',
  styleUrls: ['./solicitar.component.css']
})
export class SolicitarComponent implements OnInit{

  legal_activo: boolean = false;

  Solicitud = new FormGroup({
    nombre: new FormControl(''),
    dni: new FormControl(''),
    imp_cuota: new FormControl(''),
    email: new FormControl(''),
    region: new FormControl('tdf'),
    motivo: new FormControl(''),
    relacion: new FormControl('', [Validators.required]),
    sueldo: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(7)]),

  })

  constructor(
    private ContactoServicio: ContactoService,
    private SimuladorService: SimuladorService,
    private router: Router
    ) {}


  ngOnInit(){

    if(this.SimuladorService.simulado){

      this.Solicitud.get("nombre").setValue(this.SimuladorService.datos_simular.nombre);
      this.Solicitud.get("dni").setValue(this.SimuladorService.datos_simular.dni);
      this.calcular_cuota();

    }else{
      this.router.navigate(['/solicitud']);
    }
  }

  calcular_cuota(){

    let importe: number = this.SimuladorService.datos_simular.importe;
    let cuotas: number = this.SimuladorService.datos_simular.cuotas;
    this.ContactoServicio.Simular(importe, cuotas).subscribe(
      res => {
          this.Solicitud.get("imp_cuota").setValue(res);
      },
      err => {
          console.log(err);
          this.Solicitud.get("imp_cuota").setValue(0);
      });
  }

  check_legales(val){

    this.legal_activo = !this.legal_activo;

  }

  btn_volver(){

    this.router.navigate(['/solicitud']);

  }


  btn_solicitar(val){
    if(this.Solicitud.valid){

      let datos: any = this.Solicitud.value;
      this.SimuladorService.datos_simular = Object.assign(datos, this.SimuladorService.datos_simular);
      this.SimuladorService.solicitado = true;

      this.router.navigate(['/solicitud/thankyou']);

    }

  }

}

